import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this Saturday, November 4th!  Truegritcomp.wordpress.com.
 `}<strong parentName="p">{`**`}</strong>{`There will be no classes or open gym at either location this day.
 We will also have no 4:00pm at East or 5:30pm at The Ville on Friday in
order to set up for True Grit.***`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2"
      }}>{`https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2`}</a></p>
    <p><strong parentName="p">{`*`}{`This Friday is the last day Louisville East will be open for classes
before merging with The Ville.  The mid moring class currently at East
will move to the Ville.  The following schedule will start next week: `}</strong></p>
    <p><strong parentName="p">{`Sunday:`}</strong>{`\\
`}<strong parentName="p">{`12:30 class`}</strong></p>
    <p><strong parentName="p">{`Monday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-10:30 open gym`}</em>{`\\
`}<strong parentName="p">{`10:30-11:30 class`}</strong>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Tuesday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-10 open gym`}</em>{`\\
`}<strong parentName="p">{`10-11 class`}</strong>{`\\
`}<em parentName="p">{`11-12 open gym`}</em>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Wednesday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-9:30 open gym`}</em>{`\\
`}<strong parentName="p">{`9:30-10:30 class`}</strong>{`\\
`}<em parentName="p">{`10:30-12 open gym`}</em>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Thursday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-10 open gym`}</em>{`\\
`}<strong parentName="p">{`10-11 class`}</strong>{`\\
`}<em parentName="p">{`11-12 open gym`}</em>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Friday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-10:30 open gym`}</em>{`\\
`}<strong parentName="p">{`10:30-11:30 class`}</strong>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Saturday:`}</strong>{`\\
`}<strong parentName="p">{`9-11 classes`}</strong>{`\\
`}<em parentName="p">{`11-1 Open gym`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      